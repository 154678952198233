import { render, staticRenderFns } from "./TabData.vue?vue&type=template&id=b5aa670c&scoped=true&"
import script from "./TabData.vue?vue&type=script&lang=js&"
export * from "./TabData.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5aa670c",
  null
  
)

export default component.exports